import { useState } from 'react'
import IconChecked from '../icons/cb-checked.png';
import IconEmpty from '../icons/cb-empty.png';

export const CheckBox = ({ defaultChecked, label, onChange }) => {
    const [checked, setChecked] = useState(defaultChecked)

    return <div style={{ display: 'inline-flex', cursor: 'pointer' }} onClick={() => {
        setChecked(!checked)
        onChange(!checked)
    }}>
        <img src={checked ? IconChecked : IconEmpty} alt="checkbox" width="24"/>
        <span style={{ color: '#ED672C', marginLeft: 5, marginTop: 2 }}>{label}</span>
    </div>
}

export default CheckBox
import { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import axios from '../utils/axios';
import { logOut, refreshToken } from '../utils/auth'
import BgLogo from '../icons/bg-logo.png'
import IconPencil from '../icons/icon-pencil.png'
import IconTrash from '../icons/trash.png'
import { formatDate } from '../utils/date'
import { DISCORD_LOGIN_URL } from './Login';
import { generateAvatarImage } from '../utils/discord'
import { useDebounce } from 'use-debounce';
import CheckBox from './CheckBox'
import Swal from 'sweetalert2'
import { formatText } from '../utils/text'
import TagInput from './TagInput'

function QaList({ userInfo, createFormShown = false }) {

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
  
    // TODO WIP unanswered questions
    // const [unansweredQaList, setUnansweredQaList] = useState(null)
    const [unansweredQuestionFocus, setUnansweredQuestionFocus] = useState(null)
  
    const [qaList, setQaList] = useState(null)

    const { guild_slug } = useParams()

    const currentPage = (searchParams.get('page') || 1) * 1
    const pageSize = (searchParams.get('page_size') || 20) * 1

    const setCurrentPage = page => {
      navigate(`/${guild_slug}?page=${page}&page_size=${pageSize}`)
    }

    const setPageSize = newPageSize => {
      navigate(`/${guild_slug}?page=1&page_size=${newPageSize}`)
    }

    const setCreateFormShown = shown => {
      if(shown) {
        navigate(`/${guild_slug}/create`)
      } else {
        navigate(`/${guild_slug}`)
      }
    }

    const [totalItems, setTotalItems] = useState(null)
    const [qaItemBeingEdited, setQaItemBeingEdited] = useState(null)

    const [isPublicLoadingIds, setIsPublicLoadingIds] = useState([])

    const [searchTerm, setSearchTerm] = useState("")
    const [searchTermDebounced] = useDebounce(searchTerm, 500)

    const [community, setCommunity] = useState(undefined)
  
    const [allTagsList, setAllTagsList] = useState(null)
    const [selectedTagId, setSelectedTagId] = useState(null)

    const totalPages = Math.max(1, Math.ceil(totalItems / pageSize))

    useEffect(() => {
        axios.get(`get_community_by_slug?guild_slug=${guild_slug}`).then(response => {
            setCommunity(response.data)
            localStorage.setItem('lastGuildSlug', response.data.guild_slug)
        }).catch(() => {
            setCommunity(null)
        })
    }, [guild_slug])

    const reloadQaList = () => {
        setQaList(null)
      //   setUnansweredQaList(null)
        const tagQueryParam = selectedTagId ? `&tag_id=${selectedTagId}` : ''

        axios.get(`/qa_documents/?guild_slug=${guild_slug}&page=${currentPage}&search=${searchTermDebounced}&page_size=${pageSize}${tagQueryParam}`).then(response => {
          setQaList(response.data.results)
          setTotalItems(response.data.count)
        })

        setAllTagsList(null)

        axios.get(`/tags/?guild_slug=${guild_slug}`).then(response => {
          setAllTagsList(response.data)
        })
      //   axios.get('/get_unanswered_questions').then(response => {
      //     setUnansweredQaList(response.data)
      //   })
      }
    
    useEffect(() => {
      reloadQaList()
    }, [])

    useEffect(() => {
        reloadQaList()
    }, [currentPage, pageSize, selectedTagId])

    useEffect(() => {
      if(currentPage > 1) {
        setCurrentPage(1)
      } else {
        reloadQaList()
      }
    }, [searchTermDebounced])

    if(community === undefined) {
        return <h3>Loading community...</h3>
    }   

    if(!allTagsList) {
      return <h3>Loading tags...</h3>
    }

    if(community === null) {
        return <h3>Community not found</h3>
    }

    const guildId = community.guild_id
    const currentUserIsAdmin = !!userInfo?.managed_communities?.filter(x => x.guild_id === guildId)?.length

    if(userInfo && !currentUserIsAdmin && createFormShown) {
      setCreateFormShown(false)
    }

    const QaItem = ({ id, prompt, completion, onEdit, created_on, last_modified_on, is_public, asked_by, answered_by, last_edited_by, tags }) => {
        const hasShowAllButton = completion.length > 650 || Array.from(completion).filter(c => c === "\n").length > 7

        const [showAll, setShowAll] = useState(!hasShowAllButton)

        const notShowAllStyle = {
          display: '-webkit-box',
          WebkitLineClamp: 5,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }

        const tagIds = tags.map(x => x.id)

        return <div className="qa-card" style={{margin: '20px 0'}}>
          <div style={{ display: 'inline-block', width: 'calc(100% - 0px)'}}>
              <div className="container" style={{width: '100%', display: 'flex', maxWidth: '100%', position: 'relative'}}>
                  <div style={{ flexGrow: 1, display: 'flex', maxWidth: 'calc(100% - 100px)', justifyContent: 'space-between' }}>
                    <div className="left-column" style={{ minWidth: 200, marginRight: 15 }}>
                        <div style={{marginBottom: 10}}>
                          <div className="avatar asker" style={{width: 50, height: 50, display: 'inline-block', position: 'relative', top: 10, left: -5, marginRight: 5}}>
                            {generateAvatarImage(asked_by)}
                          </div>
                          <div style={{ display: 'inline-block', marginRight: 5 }}>
                            <div style={{color: '#ED672C', fontWeight: 'bold'}}>
                              asked by
                            </div>
                            <div>
                              {asked_by?.discord_username || 'Unknown'}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="avatar responder" style={{width: 50, height: 50, display: 'inline-block', position: 'relative', top: 10, left: -5, marginRight: 5}}>
                            {generateAvatarImage(answered_by)}
                          </div>
                          <div style={{ display: 'inline-block', marginRight: 5 }}>
                            <div style={{color: '#ED672C', fontWeight: 'bold'}}>
                              answered by
                            </div>
                            <div>
                              {answered_by?.discord_username || 'Unknown'}
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="center-column" style={{ marginTop: 10, minWidth: '30%', flexGrow: 1 }}>
                        <div style={{ display: 'flex'}}>
                          <b style={{display: 'inline-block'}}>Q: </b> 
                          <b style={{position: 'relative', paddingLeft: 10, paddingRight: 20, display: 'inline-block', color: '#ED672C', maxWidth: 'calc(100% - 30px)', overflowWrap: 'break-word', ...(
                              showAll ? {} : notShowAllStyle
                            )}}>
                              {formatText(prompt)}
                              {currentUserIsAdmin && <img onClick={onEdit} src={IconPencil} alt="edit" width="12" style={{position: 'absolute', top: 4, right: 3, cursor: 'pointer'}}/>}
                          </b>
                          
                        </div>

                        <div style={{ marginTop: 10, display: 'flex' }}>
                            <b style={{display: 'inline-block'}}>A: </b> 
                            <span style={{ paddingLeft: 10, display: 'inline-block', width: 'calc(100% - 30px)', overflowWrap: 'break-word', ...(
                              showAll ? {} : notShowAllStyle
                            )}}>
                                {formatText(completion)}
                            </span>
                        </div>

                        <div style={{ marginTop: 20, marginLeft: 20 }}>
                          <TagInput
                            selectedTagIds={tagIds}
                            viewOnly={true}
                            allTagsPreloaded={allTagsList}
                            guildSlug={guild_slug}
                          />
                        </div>

                        {hasShowAllButton && <div onClick={() => setShowAll(!showAll)} style={{
                          marginLeft: 40,
                          marginTop: 20,
                          cursor: 'pointer',
                          color: '#ED672C'
                        }}>
                          {showAll ? 'Show less' : 'Show more'}
                        </div>}
                    </div>
                  </div>
                  <div className="right-column">
                    <div style={{ minWidth: '100px', marginTop: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            marginRight: 5,
                            height: '100%',
                            justifyContent: 'space-between'
                      }}>
                        <span style={{color: '#878D9D', fontSize: 14, textAlign: 'right'}}>
                          {last_edited_by ? 
                            <>Last edit: {formatDate(new Date(last_modified_on))} by {last_edited_by.discord_username}</> :
                            <>{formatDate(new Date(created_on))}</>
                          }
                        </span>
                        {currentUserIsAdmin && (
                          <>
                            <div style={{ marginTop: 10, flexGrow: 1 }}>
                              {isPublicLoadingIds.includes(id) ? 'Loading...' :
                                <CheckBox defaultChecked={is_public} label="Public" onChange={newIsPublic => {
                                    updateQaItemIsPublic({ id, is_public: newIsPublic })
                                }}/>
                              } 
                            </div>
                            <div style={{
                                  position: 'absolute',
                                  right: 0,
                                  bottom: -7,
                                  cursor: 'pointer'
                            }}>
                              <img onClick={() => {
                                Swal.fire({
                                  title: 'Are you sure?',
                                  text: "You won't be able to revert this!",
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: '#3085d6',
                                  cancelButtonColor: '#d33',
                                  confirmButtonText: 'Yes, delete it!'
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    deleteQaItem({ id })
                                  }
                                })
                              }} src={IconTrash} alt="delete" width="22"/>
                            </div>
                          </>
                        )}
                      </div>
                  </div>
              </div>
          </div>
        </div>
      }
  
      // TODO WIP unanswered questions
      // const UnansweredQuestionItem = ({ id, prompt, created_on, onEdit }) => {
      //     return <div className="qa-card" style={{margin: '20px 0'}}>
      //       <div style={{ display: 'inline-block', width: 'calc(100% - 50px)'}}>
      //           <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
      //               <div>
      //                   <b style={{display: 'inline-block', width: 30}}>Q: </b> 
      //                   <b style={{display: 'inline-block', color: '#ED672C'}}>
      //                       {prompt}
      //                   </b>
      //                   <img onClick={onEdit} src={IconPencil} alt="edit" width="18" style={{position: 'relative', top: 5, left: 5, cursor: 'pointer'}}/>
      //               </div>
      //               <span style={{color: '#878D9D'}}>{formatDate(new Date(created_on))}</span>
      //           </div>
      //       </div>
      //     </div>
      // }
    
      const QaItemEdit = ({ isCreate, prompt = '', completion = '', is_public, onSave, onCancelEdit, onDelete, unansweredQuestionFocus, tags}) => {
        const [promptEdit, setPromptEdit] = useState(unansweredQuestionFocus?.prompt || prompt)
        const [completionEdit, setCompletionEdit] = useState(completion)
        const [isPublicEdit, setIsPublicEdit] = useState(is_public)
        const [tagIdsEdit, setTagIdsEdit] = useState(isCreate ? [] : tags.map(x => x.id))

        const isValid = promptEdit.trim().length > 0 && completionEdit.trim().length > 0

        return <div className="qa-card" style={{margin: '20px 0'}}>
              <div style={{ display: 'inline-block', width: 'calc(100% - 0px)'}}>
                  <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <b style={{display: 'inline-block', width: 30}}>Q: </b> 
                          <textarea style={{display: 'inline-block', color: '#ED672C', fontWeight: 'bold', width: 'calc(100% - 50px)'}} 
                              value={promptEdit}
                              onChange={e => setPromptEdit(e.target.value)}
                              disabled={!!unansweredQuestionFocus}
                              maxLength={2000}
                          />
                      </div>
                  </div>
                  <div style={{ marginTop: 10, width: '100%', display: 'flex', alignItems: 'center' }}>
                      <b style={{display: 'inline-block', width: 30}}>A: </b> 
                      <textarea style={{display: 'inline-block', width: 'calc(100% - 50px)', height: 100}} 
                          value={completionEdit}
                          onChange={e => setCompletionEdit(e.target.value)}
                      />
                  </div>
                  <TagInput
                    selectedTagIds={tagIdsEdit}
                    onChange={newTagIds => {
                      setTagIdsEdit(newTagIds)
                    }}
                    guildSlug={guild_slug}
                  />
                  {/* <div style={{ marginTop: 10 }}>
                      <b style={{display: 'inline-block'}}>Is Public: </b> 
                      <input type="checkbox" defaultChecked={is_public} onChange={() => setIsPublicEdit(!isPublicEdit)}/>
                  </div> */}
                  <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginRight: 20}}>
                      {/* {!isCreate && <button onClick={onDelete} className="secondary">Delete</button>} */}
                      <div>
                          <button onClick={onCancelEdit} className="secondary">Cancel</button>
                          <button 
                                onClick={() => onSave({ prompt: promptEdit, completion: completionEdit, is_public: isPublicEdit, tagIds: tagIdsEdit})} 
                                style={isValid ? {} : { pointerEvents: 'none', opacity: 0.6 }}>
                            Save
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      }


    const createQaItem = ({ prompt, completion, is_public, tagIds  }) => {
      setQaList(null)
      setCreateFormShown(false)
      axios.post('/qa_documents/', {
        prompt,
        completion,
        is_public,
        guild_id: guildId
      }).then(() => {
        reloadQaList()
      })
    }
  
    const deleteQaItem = ({ id }) => {
      setQaList(null)
      axios.delete(`/qa_documents/${id}/`).then(() => {
        reloadQaList()
      })
    }
  
    const updateQaItem = ({ id, prompt, completion, is_public, tagIds }) => {
      setQaList(null)
      setQaItemBeingEdited(null)
      axios.patch(`/qa_documents/${id}/`, {
        prompt,
        completion,
        is_public,
        tag_ids: tagIds
      }).then(() => {
        reloadQaList()
      })
    }

    const updateQaItemIsPublic = ({ id, is_public }) => {
      setIsPublicLoadingIds(isPublicLoadingIds.concat([ id ]))
      axios.patch(`/qa_documents/${id}/`, {
        is_public
      }).then(response => {
        setQaList(qaList.map(x => {
          if(x.id === id) {
            return response.data
          } else {
            return x
          }
        }))
        setIsPublicLoadingIds(isPublicLoadingIds.filter(x => x !== id))
      })
    }
  
    return <div style={{textAlign: 'left', marginTop: 20}}>
      <div className="top-bar" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <div className="community-name-container" style={{display: 'flex', alignItems: 'flex-end', flex: 1}}>
                <span style={{fontWeight:'bold'}}>FAQ of {community?.display_name}</span> 
                <div>
                  <div style={{display: 'inline-block', color: '#666666', margin: '0 5px', fontSize: 12}}>powered by</div>
                  <div style={{display: 'inline-block'}}>
                    <img src={BgLogo} alt="logo" height="40" style={{ position: 'relative', marginBottom: -12 }}/>
                  </div>
                </div>
            </div>
            <div className="auth-info-container" style={{display: 'flex', alignItems: 'center', justifySelf: 'flex-end', position: 'relative', top: 8, marginRight: 15 }}>
                  {userInfo ? 
                      <>
                          <div className="avatar" style={{display: 'inline-block', width:50, height: 50}}>
                              {generateAvatarImage(userInfo)}
                          </div>
                          <span style={{ marginLeft: 10, marginRight: 15 }}>{userInfo.discord_username}</span>
                          <a onClick={logOut} style={{color: '#EC662C', margin: '0 10px'}}>Sign Out</a>
                      </> : 
                      <>
                          <button
                              onClick={() => window.location = DISCORD_LOGIN_URL}
                          >
                              Log In
                          </button>
                      </>
                  }
            </div>
            <div className="search-bar">
                  <input type="text" placeholder="Search" style={{ minWidth: 200 }}
                        value={searchTerm}
                        onChange={e => {
                          setSearchTerm(e.target.value)
                          setQaList(null)
                        }}
                    />
            </div>
      </div>

      {/* {unansweredQaList !== null && unansweredQaList.length > 0 &&
        <>
            <h3 style={{ textAlign: 'left', marginTop: 40 }}>Unanswered Questions</h3>
            {unansweredQaList.map(x => <UnansweredQuestionItem {...x} onEdit={() => {
                setUnansweredQuestionFocus(x)
                setCreateFormShown(true)
            }}/>)}
        </>
      } */}

      <h3 style={{ textAlign: 'left', marginTop: 40 }}>
        {currentUserIsAdmin ? "Answered Questions" : "FAQ"}</h3>
        {
          allTagsList?.length ? <>
            <div>
              Filter by tags
            </div>
            <div style={{marginBottom: 40}}>
              {allTagsList?.map(tag => {
                const isSelected = tag.id === selectedTagId
                return <div style={{ 
                          display: 'inline-block',
                          background: isSelected ? 'rgba(237, 103, 44, 0.9)' : 'rgba(237, 103, 44, 0.15)',
                          fontWeight: 'bold',
                          color: isSelected ? 'white' : '#ED672C',
                          padding: '7px 15px',
                          margin: 7,
                          borderRadius: 20,
                          cursor: 'pointer'
                    }}
                    
                    onClick={isSelected ? () => setSelectedTagId(null) : () => setSelectedTagId(tag.id)}
                  >
                    {tag.name}
                  </div>
                })
              }
            </div>
          </> : ""
        }
        {searchTerm && qaList !== null && <h3>{totalItems} Search Results for "{searchTerm}" <button className="secondary" onClick={() => setSearchTerm("")}>Clear</button>
      </h3>}
      
      {qaList === null && <h3 style={{textAlign: 'center'}}>
        <br/><br/><br/><br/><br/>
        {'Loading Q&A List...'}
      </h3>}

      {userInfo && currentUserIsAdmin && <>
          {createFormShown ? <>
              <QaItemEdit isCreate={true} onCancelEdit={() => setCreateFormShown(false)} onSave={(item) => {
              createQaItem(item)
              }} unansweredQuestionFocus={unansweredQuestionFocus}/>
          </> : <>
              <div style={{position: 'fixed', width: '100vw', height: '190px', bottom: 0, left: 0, background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) -11.33%, #FFFFFF 50%)', zIndex: 1000}}>
                <button onClick={() => setCreateFormShown(true)} style={{fontSize: 18, position: 'fixed', right: 30, bottom: 30, padding: '20px 40px'}}>+ Add Q&A Pair</button>
              </div>
          </>
          }
      </>}

      {qaList !== null && qaList.length === 0 && <h4>
        No data found
      </h4>}

      {qaList !== null && qaList.length > 0 && !createFormShown && <>
        <div>
          {qaList.map(qa => {
            if(qa.id === qaItemBeingEdited?.id) {
              return <QaItemEdit {...qa} 
                        onCancelEdit={() => setQaItemBeingEdited(null)} 
                        onDelete={() => deleteQaItem(qa)}
                        onSave={(form) => updateQaItem({ id: qa.id, ...form })}
                        />
            } else {
              return <QaItem {...qa} onEdit={() => setQaItemBeingEdited(qa)}/>
            }
          })}
        </div>
        <div className="pagination" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end', margin: '0 20px', marginBottom: 200 }}>
          <div>
            <div style={{ display: 'inline-block', fontSize: 14 }}>
              Page {currentPage} of {totalPages}
            </div>
            {currentPage > 1 && <button className="text" onClick={() => setCurrentPage(currentPage - 1)}>« Prev</button>}
            <div style={{display: 'inline-block'}}>
                {currentPage - 4 >= 1 && '...'}
                {[...Array(3).keys()].map(i => {
                  const page = currentPage - 3 + i
                  if(page >= 1) {
                    return <button className="text" onClick={() => setCurrentPage(page)}>{page}</button>
                  }
                })}
                <button className="text" style={{ pointerEvents: 'none', color: '#ED672C', fontWeight: 'bold' }}>{currentPage}</button>
                {[...Array(3).keys()].map(i => {
                  const page = currentPage + 1 + i
                  if(page <= totalPages) {
                    return <button className="text" onClick={() => setCurrentPage(page)}>{page}</button>
                  }
                })}
                {currentPage + 1 + 5 <= totalPages && '...'}
                {currentPage < totalPages && <button className="text" onClick={() => setCurrentPage(currentPage + 1)}>Next » </button>}
            </div>
          </div>
          <div>
            Items per page: 
            {[20, 50, 100].map(size => {
              const isCurrentSize = size === pageSize
              const style = isCurrentSize ? {
                pointerEvents: 'none', color: '#ED672C', fontWeight: 'bold'
              } : {

              }
              return <button className="text" style={{ fontSize: 12, ...style }} onClick={() => setPageSize(size)}>{size}</button>
            })}
          </div>
        </div>
      </>}
    </div>
  }

export default QaList
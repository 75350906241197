import axios from './axios';

export const logOut = () => {
    axios.post('api/logout/', { "refresh": localStorage.getItem('refreshToken') })
  
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessToken')
  
    window.location = "/"
}

export const refreshToken = ({ callback } = {}) => {
    axios.post('/token/refresh/', {
        refresh: localStorage.getItem('refreshToken')
    }).then(response => {
        localStorage.setItem('accessToken', response.data.access)
        if(callback) {
        callback()
        }
    }).catch(() => {
        logOut()
    })
}

import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react';
import axios from '../utils/axios';

function DiscordLoginCallback({ refreshUser }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")
  
    useEffect(() => {
      axios.post('/login_with_discord_code', {
        "code": code
      }).then(response => {
        const { success, refresh, access } = response.data
        if(!success) {
            console.log('error', response.data)
            window.location = "/"
        }
        localStorage.setItem('accessToken', access)
        localStorage.setItem('refreshToken', refresh)
        window.location = "/" + localStorage.getItem('lastGuildSlug')
      }).catch(e => {
        console.log('error', e)
        window.location = "/"
      })
    }, [])
  
    return <h3 style={{ textAlign: 'center' }}>
        Loading...
    </h3>
}

export default DiscordLoginCallback;
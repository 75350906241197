import React, { useState, useEffect, useRef } from 'react';
import axios from '../utils/axios';
import DeleteIcon from '../icons/delete-icon.png'
import PencilIcon from '../icons/icon-pencil.png'
import TrashIcon from '../icons/trash.png'

import { BallTriangle } from  'react-loader-spinner'

function TagItem({ tag, onClick, className, isSelected, onDelete, onEdit, viewOnly }) {
    return <>
        <div style={{
            display: 'inline-block',
            margin: 5,
            padding: 5,
            cursor: onClick && !viewOnly ? 'pointer' : 'default',
            background: isSelected ? 'rgba(237, 103, 44, 0.3)' : 'rgba(237, 103, 44, 0.15)',
            borderRadius: 3,
            color: '#ED672C',
            fontWeight: 'bold'
        }} onClick={!viewOnly && onClick} className={!viewOnly && className}>
            {onDelete && !viewOnly && <img 
                            src={DeleteIcon} alt="delete" 
                            onClick={e => {
                                e.stopPropagation()
                                onDelete()
                            }} 
                            width="10" 
                            style={{ 
                                marginRight: 3,
                                cursor: 'pointer'
                            }}
                        />}
            {tag.name}
        </div>
        {onEdit && !viewOnly && <img 
            src={PencilIcon} alt="edit" 
            onClick={e => {
                e.stopPropagation()
                onEdit()
            }} 
            width="10" 
            style={{ 
                marginRight: 3,
                cursor: 'pointer'
            }}
        />}
    </>
}

export function TagInput({ selectedTagIds, allTagsPreloaded, guildSlug, onChange, viewOnly }) {
    const [allTags, setAllTags] = useState(allTagsPreloaded || null)
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [currentInputedText, setCurrentInputedText] = useState("")
    const [tagBeingEdited, setTagBeingEdited] = useState(null)
    const [newTagNameInput, setNewTagNameInput] = useState("")
    const [isLoading, setIsLoading] = useState(!allTagsPreloaded)
    const [cursorAfterTagIndex, setCursorAfterTagIndex] = useState(null)

    const overlayInputEl = useRef(null);

    function focusOnInput() {
        setTimeout(() => {
            overlayInputEl.current && overlayInputEl.current.focus()
        }, 100)
    }

    function loadAllTags(focusOnInputAfterLoad) {
        setIsLoading(true)
        axios.get(`tags/?guild_slug=${guildSlug}`).then(response => {
            setAllTags(response.data)
            setTimeout(() => {
                setIsLoading(false)
                if(focusOnInputAfterLoad) {
                    focusOnInput()
                }
            }, 100)
        })
    }

    useEffect(() => {
        if(!allTagsPreloaded) {
            loadAllTags()
        }
    }, [])

    if(!allTags || isLoading) {
        return <div style={{
            marginTop: 50,
            marginLeft: 100
        }}>
            <BallTriangle
                height="50"
                width="50"
                color='#ED672C'
                ariaLabel='loading'
            />
        </div>
    }

    const onTagRename = ({tag, newName}) => {
        setIsLoading(true)
        axios.patch(`tags/${tag.id}/`, {
            name: newTagNameInput
        }).then(response => {
            loadAllTags()
        })
    }

    const onTagDelete = (tagId) => {
        setIsLoading(true)
        axios.delete(`tags/${tagId}/`).then(response => {
            loadAllTags()
        })
    }

    const onTagCreateAndAdd = (tagName) => {
        setIsLoading(true)
        axios.post(`tags/`, { 
            name: tagName,
            guild_slug: guildSlug
        }).then(response => {
            onChange(selectedTagIds.concat([response.data.id]))
            loadAllTags(true)
        })
    }

    const selectedTags = selectedTagIds.map(id => allTags.find(x => x.id === id)).filter(x => x)

    const filteredAllTags = allTags.filter(x => x.name.toLowerCase().includes(currentInputedText))

    const showCreateButton = currentInputedText.length > 0 && !allTags.find(x => x.name === currentInputedText)

    if(viewOnly) {
        return <div>
            {selectedTags.map(tag => {
                return <TagItem
                    tag={tag}
                    onDelete={() => {
                        onChange(selectedTagIds.filter(id => id !== tag.id))
                    }}
                    viewOnly={viewOnly}
                />
            })}
        </div>
    }

    const input = <input type="text" 
        ref={overlayInputEl}
        className="no-outline"
        value={currentInputedText} 
        onChange={e => {
            if(cursorAfterTagIndex !== null) {
                setCursorAfterTagIndex(null)
                focusOnInput()
            }
            setCurrentInputedText(e.target.value.substr(0, 30))
        }}
        style={{
            border: 'none',
            minWidth: cursorAfterTagIndex !== null ? 10 : 350,
            width: cursorAfterTagIndex !== null ? 10 : undefined,
            boxShadow: 'none',
            margin: 3
        }}
        onKeyDown={(e) => {
            console.log(e)
            if(e.key === 'Enter') {
                if(showCreateButton) {
                    onTagCreateAndAdd(currentInputedText)
                    setCurrentInputedText('')
                } else {
                    const matchTag = allTags.find(x => x.name === currentInputedText)
                    if(matchTag && !selectedTagIds.includes(matchTag.id)) {
                        onChange(selectedTagIds.concat([matchTag.id]))
                        setCurrentInputedText('')
                    }
                }
            }
            if(e.key === 'Backspace' && !currentInputedText?.length) {
                if(selectedTagIds.length > 0) {
                    if(cursorAfterTagIndex !== null) {
                        onChange(
                            selectedTagIds.slice(0, cursorAfterTagIndex).concat(
                                selectedTagIds.slice(cursorAfterTagIndex + 1, selectedTagIds.length)
                            )
                        )
                        setCursorAfterTagIndex(
                            Math.max(cursorAfterTagIndex - 1, 0)
                        )
                        focusOnInput()
                    } else {
                        onChange(selectedTagIds.slice(0, selectedTagIds.length - 1))
                    }
                }
            }
            if(!currentInputedText?.length) {
                if(e.key === "ArrowLeft") {
                    if(cursorAfterTagIndex === null && selectedTagIds.length > 1) {
                        setCursorAfterTagIndex(selectedTagIds.length - 2)
                    }
                    if(cursorAfterTagIndex > 0) {
                        setCursorAfterTagIndex(cursorAfterTagIndex - 1)
                    }
                    focusOnInput()
                }
                if(e.key === "ArrowRight") {
                    if(cursorAfterTagIndex !== null && cursorAfterTagIndex < selectedTagIds.length - 2) {
                        setCursorAfterTagIndex(cursorAfterTagIndex + 1)
                    }
                    if(cursorAfterTagIndex === selectedTagIds.length - 2) {
                        setCursorAfterTagIndex(null)
                    }
                    focusOnInput()
                }
            }
        }}
    />

    return <div style={{
        marginTop: 25,
        marginLeft: 30,
        marginRight: 20,
    }}>
        <div style={{
            marginBottom: 5,
            fontSize: 12,
            marginLeft: 3
        }}>Categories</div>
        <div>
            <div style={{
                minHeight: 40,
                background: 'white',
                border: '1px solid #F0EFEF',
                boxSizing: 'border-box',
                boxShadow: 'inset 2px 2px 2px rgba(0, 0, 0, 0.15)',
                width: 'calc(100% - 0)',
                cursor: 'text',
                position: 'relative',
                padding: 1
            }} onClick={() => { 
                setSelectorOpen(true)
                setTimeout(() => {
                    overlayInputEl.current && overlayInputEl.current.focus()
                }, 100)
            }}>
                {selectedTags.map(tag => {
                    return <TagItem
                        tag={tag}
                        onDelete={() => {
                            onChange(selectedTagIds.filter(id => id !== tag.id))
                        }}
                        viewOnly={viewOnly}
                    />
                })}

                <input type="text" 
                    className="no-outline"
                    value={currentInputedText} 
                    onChange={e => setCurrentInputedText(e.target.value)}
                    style={{
                        border: 'none',
                        boxShadow: 'none',
                        margin: 3,
                        minWidth: 350
                    }}
                />

                <div style={{ display: selectorOpen ? 'block' : 'none' }}>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 9995,
                        background: 'rgb(0,0,0,.05)',
                        cursor: 'default',
                    }} onClick={e => {
                        e.stopPropagation()
                        setSelectorOpen(false)
                    } }/>

                    <div style={{
                        minHeight: 40,
                        background: 'white',
                        boxSizing: 'border-box',
                        boxShadow: 'inset 2px 2px 2px rgba(0, 0, 0, 0.15)',
                        width: 'calc(100% - 0)',
                        cursor: 'text',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        top: 0,
                        zIndex: 9998,
                        padding: 1
                    }} onClick={() => setSelectorOpen(true)}>
                        {selectedTags.map((tag, i) => {
                            return <>
                                <TagItem
                                    tag={tag}
                                    onDelete={() => {
                                        onChange(selectedTagIds.filter(id => id !== tag.id))
                                    }}
                                    viewOnly={viewOnly}
                                />
                                {cursorAfterTagIndex === i && input}
                            </>
                        })}
                        {cursorAfterTagIndex === null && input}
                    </div>

                    <div style={{ 
                        position: 'absolute', 
                        top: '100%',
                        // border: '1px solid #F0EFEF',
                        boxSizing: 'border-box',
                        boxShadow: 'inset 2px 2px 2px rgba(0, 0, 0, 0.05)',
                        width: '100%',
                        minHeight: '40px',
                        zIndex: 9999,
                        background: 'white',
                    }}>
                        {filteredAllTags.map(tag => {
                            const isSelected = selectedTagIds.includes(tag.id)
                            return <div>
                                <div style={{ display: 'inline-block', position: 'relative' }}>
                                    <TagItem
                                        viewOnly={viewOnly}
                                        className={isSelected ? "tag-hover-selected" : "tag-hover"}
                                        tag={tag}
                                        onClick={isSelected ? () => {
                                            const updated = selectedTagIds.filter(id => id !== tag.id)
                                            onChange(updated)
                                            setCurrentInputedText('')
                                        } : () => {
                                            onChange(selectedTagIds.concat([tag.id]))
                                            setCurrentInputedText('')
                                        }}
                                        isSelected={isSelected}
                                        onEdit={() => {
                                            setTagBeingEdited(tag.id)
                                            setNewTagNameInput(tag.name)
                                        }}
                                    />
                                    {tag.id === tagBeingEdited && <>
                                        <div style={{
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 10000,
                                            background: 'rgb(0,0,0,.05)',
                                            cursor: 'default',
                                        }} onClick={e => {
                                            e.stopPropagation()
                                            if(newTagNameInput && newTagNameInput !== tag.name) {
                                                onTagRename({ tag, newName: newTagNameInput })
                                            }
                                            setTagBeingEdited(null)
                                        }}/>
                                        <div style={{ 
                                            position: 'absolute',
                                            left: '105%',
                                            top: 0,
                                            background: 'white',
                                            border: '1px solid gray',
                                            borderRadius: 3,
                                            padding: 15,
                                            zIndex: 10001
                                        }} onClick={e => e.stopPropagation()}>
                                            <input type="text" value={newTagNameInput} onClick={e => e.stopPropagation()} onChange={e => setNewTagNameInput(e.target.value)}/>
                                            <button style={{ marginTop: 30, padding: '5px' }} onClick={() => {
                                                        onTagDelete(tagBeingEdited)
                                                    }} >
                                                <img src={TrashIcon} alt="trash" width="16" 
                                                    style={{ position: 'relative', top: 2, filter: 'brightness(0) invert(1)', marginRight: 3 }} 
                                                />
                                                Delete tag
                                            </button>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        })}
                        {
                            !showCreateButton && filteredAllTags.length === 0 && <div style={{ margin: 10 }}>
                                No tags found. Type to create new tag
                            </div>
                        }
                        {showCreateButton && <div className="gray-hover" style={{padding: 5, cursor: 'pointer'}} onClick={() => {
                            onTagCreateAndAdd(currentInputedText)
                            setCurrentInputedText('')
                        }}>
                            Create <div style={{
                                display: 'inline-block',
                                padding: 5,
                                background: 'rgba(237, 103, 44, 0.15)',
                                borderRadius: 3
                            }}>
                                {currentInputedText}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default TagInput
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    // headers: {'X-Custom-Header': 'foobar'}
  });

instance.interceptors.request.use(config => {
  if(localStorage.getItem('accessToken')) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken');
  }
  return config;
});

export default instance
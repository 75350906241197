import { useEffect, useRef, useState } from "react";
import './App.css';
import { BrowserRouter, Route, Routes, useSearchParams, useNavigate } from "react-router-dom"
import axios from './utils/axios'
import ReactInterval from 'react-interval';
import DiscordLoginCallback from './components/DiscordLoginCallback';
import Login from './components/Login';
import QaList from "./components/QaList";
import { refreshToken } from './utils/auth'


const Redirect = ({ url }) => {
  window.location = url

  return 'Redirecting...'
}

function App() {
  const [userInfo, setUserInfo] = useState(undefined)

  const lastGuildSlug = localStorage.getItem('lastGuildSlug')

  const isAuthenticated = !!userInfo

  const isDiscordCallbackRoute = window.location.pathname === "/discord_login_callback"

  const refreshUser = () => {
    if(localStorage.refreshToken) {
      axios.get('/get_user').then(response => {
        setUserInfo(response.data)
      }).catch(() => {
        setUserInfo(null)
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('accessToken')
      })
    } else {
      setUserInfo(null)
    }
  }

  useEffect(() => {
    refreshUser()
  }, [])

  if(userInfo === undefined && !isDiscordCallbackRoute) {
    return <h3 style={{textAlign: 'center'}}>
      Loading user info...
    </h3>
  }

  const guestRoutes = <>
    <Route exact path="/:guild_slug" element={<QaList userInfo={null}/>}/>
    {lastGuildSlug ?
      <Route exact path="*" element={<Redirect url={`/${lastGuildSlug}`}/>}/> :
      <Route exact path="*" element={<div>
        <h3>Community not found</h3>
      </div>}/>
    }
  </>

  const authRoutes = <>
      <Route exact path="/:guild_slug/create" element={<QaList userInfo={userInfo} createFormShown={true}/>}/>
      <Route exact path="/:guild_slug" element={<QaList userInfo={userInfo}/>}/>
      {lastGuildSlug ?
        <Route exact path="*" element={<Redirect url={`/${lastGuildSlug}`}/>}/> :
        <Route exact path="*" element={<div>
          <h3>Community not found</h3>
        </div>}/>
      }
  </>

  return <div className="root">
      <div className="root-container">
        <ReactInterval timeout={3 * 60 * 1000} enabled={!!userInfo} callback={refreshToken} />
        <Routes>
            <Route exact path="/discord_login_callback" element={<DiscordLoginCallback setUserInfo={setUserInfo}/>}/>
            {
              !isDiscordCallbackRoute && <>
                {isAuthenticated && authRoutes}
                {!isAuthenticated && guestRoutes}
              </>
            }
        </Routes>
      </div>
  </div>
}

function RouterApp() {
  return <BrowserRouter>
    <App/>
  </BrowserRouter>
}

export default RouterApp;
